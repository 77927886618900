import { useDebugValue, useEffect, useState, useRef } from "react"
import { v4 as uuidv4 } from 'uuid';
import sounds_file from "../../sounds.json"
import styles from "./styles/Soundboards.module.scss"
import { Section, SoundboardButton } from "../../Components"

const Soundboards = () => {
    const [volume, setVolume] = useState(window.localStorage.getItem("volume") || 50)
    const [mute, setMute] = useState(window.localStorage.getItem("mute") === "true" || false)
    const [CurrentPlaying, SetCurrentPlaying] = useState({})
    const [web, Setweb] = useState()
    const [counter, SetCounter] = useState(0)
    const [songs, setSongs] = useState(sounds_file)
    const [editedSongs, setEditedSongs] = useState(songs)
    const [isbackgroundchanging, setisbackgroundchanging] = useState(false);
    const [seconds, setSeconds, refSeconds] = useStateAndRef(0);

    function useStateAndRef(initial) {
        const [value, setValue] = useState(initial);
        const valueRef = useRef(value);
        valueRef.current = value;
        return [value, setValue, valueRef];
      }

    useEffect(() => {
        console.log("useEffect[seconds] executing");
        console.log(process.env.REACT_APP_WS_URL);
        const interval1 = setInterval(() => {
            console.log(isbackgroundchanging)
            document.getElementById("test").style.background = isbackgroundchanging ? `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)})` : "#17182f"
            console.log("test")
            setSeconds((seconds) => seconds + 1);
        }, 70);


        return () => {
            clearInterval(interval1);
        };
    }, [seconds]);

    useEffect(() => {
        console.log("Connecting to WebSocket...");
        console.log(process.env.REACT_APP_WS_URL);
        var websocket = new WebSocket(process.env.REACT_APP_WS_URL);

        websocket.onopen = () => {
            console.log('Websocket Connected');
        }
        websocket.onmessage = (event) => {
            event.data.text()
                .then(sound => {
                    try {
                        sound = JSON.parse(sound)
                        if (sound["type"] == "volume") {
                            setVolume(sound["name"])
                        }
                        else if (sound["type"] == "play") {
                            if (CurrentPlaying[sound["name"]] == undefined || CurrentPlaying[sound["name"]]?.paused) {
                                try {
                                    let temp = CurrentPlaying
                                    temp[sound["name"]] = new Audio(`/songs/${sound["name"]}.mp3`)
                                    temp[sound["name"]].volume = volume / 100
                                    temp[sound["name"]].play()
                                    SetCurrentPlaying(temp)
                                    console.log("Playing : " + sound["name"])
                                }

                                catch (e) { console.log(e) }
                            }

                        }
                        else if (sound["type"] == "stop") {
                            if (true) {
                                let temp = CurrentPlaying
                                temp[sound["name"]].pause()
                                temp[sound["name"]] = new Audio()
                                SetCurrentPlaying(temp)
                            }
                        }
                        else if (sound["type"] == "background") {
                            setisbackgroundchanging(sound["name"])
                        }
                        let cpa = counter + (Math.random() * 10)
                        SetCounter(cpa)
                    }
                    catch (e) { console.log(e) }
                })
        }
        Setweb(websocket)
    }, [])

    useEffect(() => {
        for (let i of Object.keys(CurrentPlaying)) {
            CurrentPlaying[i].volume = !mute ? volume / 100 : 0
        }
        try { updateInputRangeBackground(volume) } catch (e) { }

        window.localStorage.setItem("volume", volume)
        window.localStorage.setItem("mute", mute)
    }, [volume, mute, counter])


    const start_playing = (sound) => {
        web.send(JSON.stringify({ "name": sound, "type": "play" }))
        if (sound == "Homer TG =)") {
            try {
                setTimeout(() => {
                    for (let i of songs) {
                        for (let a of i["items"]) {
                            web.send(JSON.stringify({ "name": a, "type": "stop" }))
                        }
                    }
                    web.send(JSON.stringify({ "name": sound, "type": "stop" }))
                }, 1000)
            }
            catch (e) { console.log(e) }
        }
        if (sound == "zest parti") {
            setTimeout(() => {
                for (let i of songs) {
                    for (let a of i["items"]) {
                        web.send(JSON.stringify({ "name": a, "type": "play" }))
                    }
                }
                web.send(JSON.stringify({ "name": sound, "type": "stop" }))
            }, 1000)
        }
    }
    const stop_playing = (sound) => {
        web.send(JSON.stringify({ "name": sound, "type": "stop" }))
    }
    useEffect(() => {
        // create a interval and get the id

        const myInterval = setInterval(() => {
            setEditedSongs((editedSongs) => JSON.parse(JSON.stringify(editedSongs)));
        }, 10);

        // clear out the interval using the id when unmounting the component
        return () => clearInterval(myInterval);
    }, []);

    const buttonify = (songs) => {
        let l = []
        songs.map((song) => {
            l.push(<SoundboardButton CurrentPlaying={CurrentPlaying} name={song} start_playing={start_playing} stop_playing={stop_playing} />)
        })
        return l
    }

    const OnInputChange = (e) => {
        let temp = []
        for (let section of songs) {
            let tempa = []
            for (let song of section["items"]) {
                if (song.toLowerCase().includes(e.target.value.toLowerCase())) {
                    tempa.push(song)
                }
            }
            console.log(JSON.stringify(tempa))
            if (!(JSON.stringify(tempa) == "[]")) {
                temp.push({ ...section, items: tempa })
            }
        }
        setEditedSongs(temp)
    }

    const updateInputRangeBackground = (value) => {
        document.getElementById("volume").style.background = `
            linear-gradient(
                to right,
                rgba(235, 128, 29, 1) 0%,
                rgba(255, 202, 65, 1) ${value}%,
                rgba(255, 255, 255, 0.4) ${value}%,
                rgba(255, 255, 255, 0.4) 100%
            )
        `

        // rgba(235, 128, 29, 1) ${value/2}%,
        // rgba(255, 202, 65, 1) ${value/2}%,
    }

    return (<>
        <main className={styles.main}>
            <p className={styles.title}>TUL's Soundboard</p>
            <div className={styles.tools}>
                <div className={styles.search}>
                    <img src="/img/magnifying_glass.svg" />
                    <div>
                        <input placeholder="Type to search a song" onChange={OnInputChange} />
                        <span />
                    </div>
                </div>
                <div className={styles.soundVolume}>
                    <img src={!mute ? "/img/speaker.svg" : "/img/speaker_mute.svg"} className="user_select" onClick={e => setMute(!mute)} />
                    {!mute && <>
                        <input id="volume" type="range" min="0" max="100" value={volume} onChange={e => setVolume(e.target.value)} />
                        <p>{volume}%</p>
                    </>}
                    {window.localStorage.getItem("tokean") == "test" &&
                        <>
                            <button style={{ height: "100px", width: "100px" }} onClick={() => { 
                                web.send(JSON.stringify({ "name": !isbackgroundchanging, "type": "background" }))
                                
                                }}>{isbackgroundchanging ? "stop" : "start"}</button>
                            <input id="volumea" type="range" min="0" max="100" value={volume} onChange={e => {
                                setVolume(e.target.value)
                                web.send(JSON.stringify({ "name": e.target.value, "type": "volume" }))

                            }} />
                            <p>{volume}%</p></>}
                </div>
            </div>
            <div className={styles.sections}>
                {editedSongs.map((section, index) =>
                    <Section name={section["section_name"]} arrayOfButtons={buttonify(section["items"])} opened={index === 0}></Section>
                )}
            </div>
        </main>
    </>)
}

export default Soundboards
