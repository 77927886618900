import "./App.scss"
import { Soundboards, Login, Register } from "./Pages";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect, useState } from "react";


function App() {

  return (<>
    <Router>
      <Routes>
        <Route path="/" element={<Soundboards/>} />
      </Routes>
    </Router>
    </>
    )
}

export default App;
